import React, { useEffect, useRef } from "react";

import { Box, Button, Fade, Typography, useMediaQuery } from "@mui/material";

import { mobileMaxWidthMediaQuery } from "@taghere/common-utils";

import { tmrColorPalette } from "@/consts/colors";
import { TAGHERE_TALLY_BUTTON } from "@/consts/ui";
import { LANDING_BUTTON_LINK_BIZCONNECT } from "@/consts/url";

import LandingLayout from "../v1/LandingLayout";

interface Section1Props {
    tallyLink: string;
}

const Section1: React.FC<Section1Props> = ({ tallyLink }) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

 

    return (
        <LandingLayout
            sx={{
                textAlign: "center",
                paddingY: "60px",
                maxWidth: "1200px",
                marginY: 0,
                marginTop: "60px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant={isMobile ? "f28B" : "f48B"}
                    sx={{
                        textAlign: "center",
                        color: tmrColorPalette.grey[900],
                        whiteSpace: "pre-wrap",
                        lineHeight: 1.3,
                        letterSpacing: "-1px",
                        ...(isMobile && {
                            letterSpacing: "-0.6px",
                            lineHeight: 1.3,
                        }),
                    }}
                >
                    {isMobile
                        ? `1등 스마트오더 태그히어,\n위약금, 약정없이\n언제든 해지 가능!`
                        : `1등 스마트오더 태그히어,\n위약금, 약정없이 언제든 해지 가능!`}
                </Typography>
                <Typography
                    variant={isMobile ? "f16M" : "f22M"}
                    sx={{
                        textAlign: "center",
                        color: tmrColorPalette.grey[400],
                        whiteSpace: "pre-wrap",
                        lineHeight: "1.3",
                        letterSpacing: "-0.4px",
                        marginTop: "30px",
                        ...(isMobile && {
                            lineHeight: 1.5,
                        }),
                    }}
                >
                    {isMobile
                        ? "비용없이 모든 유료 기능\n14일 무료체험 가능"
                        : "비용없이 모든 유료 기능 14일 무료체험 가능"}
                </Typography>
                <Button
                    id={"goTallyButton"}
                    variant="contained"
                    sx={{
                        mt: 8,
                        padding: "15px 20px",
                        borderRadius: "100px",
                        height: "54px",
                        width: "173px",
                        backgroundColor: tmrColorPalette.primary[500],

                        ...(isMobile && {
                            width: "fit-content",
                            padding: "15px 20px",
                            borderRadius: "100px",
                            height: "56px",
                            maxWidth: "186px",
                        }),
                    }}
                    href={tallyLink}
                >
                    <Typography
                        id={"goTallyButton"}
                        variant={"f18B"}
                        sx={{
                            lineHeight: 1,
                            color: tmrColorPalette.grey[900],
                        }}
                    >
                        {TAGHERE_TALLY_BUTTON}
                    </Typography>
                </Button>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    height: isMobile ? "436px" : "600px",
                    marginTop: isMobile ? "47px" : "40px",
                    borderRadius: "20px",
                }}
            >

                {isMobile ?
                   <img src="/assets/images/lp/section_2_mo.gif" alt="animated GIF" 
                   style={{
                       width: "100%",
                       height: "100%",
                       objectFit: "cover",
                       borderRadius: "20px",
                   }}
                 />:
                 <video
                     src={"/assets/images/lp/section_2_pc.mp4"}
                     autoPlay
                     loop
                     playsInline
                     muted={true}
                     style={{
                         width: "100%",
                         height: "100%",
                         objectFit: "cover",
                         borderRadius: "20px",
                     }}
                 />
                }
               
             
            </Box>
        </LandingLayout>
    );
};

export default Section1;
