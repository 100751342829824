import React from "react";

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

import { mobileMaxWidthMediaQuery, sixHundMaxWidthMediaQuery } from "@taghere/common-utils";

import LandingLayout from "@/components/landing/v1/LandingLayout";
import { tmrColorPalette } from "@/consts/colors";

const cards = [
    { title: "윈도우" },
    { title: "애플 MAC" },
    { title: "안드로이드" },
    { title: "애플 IOS" },
];
const Section3 = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const is600 = useMediaQuery(sixHundMaxWidthMediaQuery);

    return (
        <LandingLayout
            sx={{
                textAlign: "center",
                paddingY: "100px",
                maxWidth: isMobile ? "100%" : "996px",
                marginY: 0,
                paddingX: is600 ? "30px" : "auto",
            }}
        >
            <Typography
                variant={isMobile ? "f28B" : "f36B"}
                sx={{
                    whiteSpace: "pre-line",
                    color: tmrColorPalette.grey[900],
                    lineHeight: "150%",
                    ...(isMobile && {
                        lineHeight: 1.4,
                        letterSpacing: "-0.6px",
                    }),
                }}
            >
                {isMobile ? "추가적인 구매\n필요없이" : "추가적인 구매 필요없이"}
            </Typography>
            <Box sx={{ height: isMobile ? "30px" : "16px" }} />

            <Typography
                variant={isMobile ? "f16M" : "f22M"}
                sx={{
                    color: tmrColorPalette.grey[400],
                    whiteSpace: "pre-line",
                    lineHeight: 1.3,
                    letterSpacing: "-0.4px",
                    ...(isMobile && {
                        lineHeight: 1.5,
                    }),
                }}
            >
                {isMobile
                    ? "서브 포스기, 프린터기, 단말기\n추가적인 제품 구매없이\n사용하시던 제품들 그대로 사용하세요!"
                    : "서브 포스기, 프린터기, 단말기\n추가적인 제품 구매없이 사용하시던 제품들 그대로 사용하세요!"}
            </Typography>

            <Grid
                container
                sx={{
                    display: "flex",
                    gap: isMobile ? 3 : 4,
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    marginTop: "60px",
                }}
            >
                {cards.map(({ title }) => (
                    <Grid
                        xs={isMobile || is600 ? 5.7 : 2.5}
                        item
                        key={title}
                        sx={{
                            height: "60px",
                            minWidth: "100px",
                            border: `1px solid ${tmrColorPalette.grey[200]}`,
                            backgroundColor: tmrColorPalette.white[100],
                            borderRadius: "10px",
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Typography
                            variant={isMobile ? "f14M" : "f20M"}
                            sx={{
                                color: tmrColorPalette.grey[600],
                                lineHeight: 1.5,
                            }}
                        >
                            {title}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </LandingLayout>
    );
};

export default Section3;
