import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const XHumanIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
            >
                <path
                    d="M27.5026 26.6667L35.8359 35M35.8359 26.6667L27.5026 35M20.0026 25.8333H12.5026C10.1767 25.8333 9.01369 25.8333 8.06737 26.1204C5.9367 26.7667 4.26934 28.4341 3.623 30.5648C3.33594 31.5111 3.33594 32.6741 3.33594 35M24.1693 12.5C24.1693 16.6421 20.8114 20 16.6693 20C12.5271 20 9.16927 16.6421 9.16927 12.5C9.16927 8.35786 12.5271 5 16.6693 5C20.8114 5 24.1693 8.35786 24.1693 12.5Z"
                    stroke="black"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
};

export default XHumanIcon;
