import React from "react";

import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";

import {
    mobileMaxWidthMediaQuery,
    nineHundMaxWidthMediaQuery,
    tabletMaxWidthMediaQuery,
    tenHundMaxWidthMediaQuery,
} from "@taghere/common-utils";

import LandingLayout from "@/components/landing/v1/LandingLayout";
import { tmrColorPalette } from "@/consts/colors";

const Section3_1 = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const is1000 = useMediaQuery(tenHundMaxWidthMediaQuery);
    const is900 = useMediaQuery(nineHundMaxWidthMediaQuery);
    const istablet = useMediaQuery(tabletMaxWidthMediaQuery);

    const blocks = [
        {
            pcImage: "/assets/images/lp/external_pos_logos/logo_1.png",
            moImage: "/assets/images/lp/external_pos_logos/logo_1_mobile.png",
            alt: "ok pos",
        },
        {
            pcImage: "/assets/images/lp/external_pos_logos/logo_2.png",
            moImage: "/assets/images/lp/external_pos_logos/logo_2_mobile.png",
            alt: "KIS 정보통신",
        },
        {
            pcImage: "/assets/images/lp/external_pos_logos/logo_3.png",
            moImage: "/assets/images/lp/external_pos_logos/logo_3_mobile.png",
            alt: "나이스정보통신",
        },
        {
            pcImage: "/assets/images/lp/external_pos_logos/logo_4.png",
            moImage: "/assets/images/lp/external_pos_logos/logo_4_mobile.png",
            alt: "한울정보통신",
        },
        {
            pcImage: "/assets/images/lp/external_pos_logos/logo_5.png",
            moImage: "/assets/images/lp/external_pos_logos/logo_5_mobile.png",
            alt: "easy pos",
        },
        {
            pcImage: "/assets/images/lp/external_pos_logos/logo_6.png",
            moImage: "/assets/images/lp/external_pos_logos/logo_6_mobile.png",
            alt: "union pos",
        },
        {
            pcImage: "/assets/images/lp/external_pos_logos/logo_7.png",
            moImage: "/assets/images/lp/external_pos_logos/logo_7_mobile.png",
            alt: "metacity",
        },
    ];

    return (
        <LandingLayout
            sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingY: isMobile ? 16 : 20,

                paddingX: isMobile ? "40px" : is1000 || istablet ? "30px" : "auto",
                backgroundColor: tmrColorPalette.white[100],
                maxWidth: isMobile ? "100%" : "1200px",

                marginY: 0,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: isMobile ? "100%" : "1200px",
                }}
            >
                <Typography
                    variant={isMobile ? "f16SB" : "f20B"}
                    sx={{
                        color: tmrColorPalette.primary[500],
                        lineHeight: 1.3,
                        letterSpacing: "-0.4px",
                        ...(isMobile && {
                            lineHeight: 1.5,
                        }),
                    }}
                >
                    포스 본사 공식계약
                </Typography>
                <Box sx={{ height: "4px" }} />
                <Typography
                    variant={isMobile ? "f28B" : "f36B"}
                    sx={{
                        whiteSpace: "pre-line",
                        color: tmrColorPalette.grey[900],
                        lineHeight: 1.3,
                        letterSpacing: "-0.6px",
                    }}
                >
                    {isMobile
                        ? "포스 연동으로\n주문 누락없이\n주방 주문서도 바로!"
                        : "포스 연동으로 주문 누락없이\n주방 주문서도 바로!"}
                </Typography>
                <Box sx={{ height: isMobile ? "30px" : "20px" }} />
                <Typography
                    variant={isMobile ? "f16M" : "f22M"}
                    sx={{
                        color: tmrColorPalette.grey[400],
                        lineHeight: "130%",
                        letterSpacing: "-0.4px",
                        whiteSpace: "pre-wrap",
                        ...(isMobile && {
                            lineHeight: 1.5,
                        }),
                    }}
                >
                    {isMobile
                        ? "7사의 POS 가 아닌 경우 전문 상담을 통해\n원활하게 연동을 안내합니다"
                        : "7사의 POS 가 아닌 경우 전문 상담을 통해 원활하게 연동을 안내합니다"}
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        gap: isMobile ? "16px" : "18px",
                        alignItems: "center",
                        justifyContent: is900 ? "center" : "left",
                        marginX: "auto",
                        position: "relative",
                        marginTop: isMobile ? 10 : 8,
                        marginBottom: isMobile ? 7 : 12,
                        maxWidth: "800px",
                        flexWrap: "wrap",
                        width: "auto",

                        ...(isMobile && {
                            flexDirection: "column",
                            gap: "16px",
                            paddingX: "27px",
                        }),
                    }}
                >
                    {blocks.map((block, idx) => (
                        <Box
                            key={isMobile ? block.moImage : block.pcImage}
                            data-aos="zoom-in"
                            data-aos-delay={`${isMobile ? 0 : 200 * idx}`}
                            sx={{
                                height: isMobile ? "100px" : "70px",
                                width: isMobile ? "100%" : "auto",
                            }}
                        >
                            <img
                                src={block.pcImage}
                                alt={block.alt}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        borderRadius: "20px",
                    }}
                >
                    <video
                        src={
                            isMobile
                                ? "/assets/images/lp/pos_mobile.mp4"
                                : "/assets/images/lp/pos_pc.mp4"
                        }
                        autoPlay
                        loop
                        playsInline
                        muted={true}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            opacity: 1,
                            borderRadius: "20px",
                        }}
                    />
                </Box>
                <Typography
                    variant="f15M"
                    sx={{
                        lineHeight: 1.5,
                        color: tmrColorPalette.grey[300],
                        paddingTop: "30px",
                    }}
                >
                    *태블릿(안드로이드) 포스는 별도로 문의해주세요
                </Typography>
            </Box>
        </LandingLayout>
    );
};

export default Section3_1;
