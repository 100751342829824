import React from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";

import {
    mobileMaxWidthMediaQuery,
    sixHundMaxWidthMediaQuery,
    tenHundMaxWidthMediaQuery,
} from "@taghere/common-utils";

import LandingLayout from "@/components/landing/v1/LandingLayout";
import { tmrColorPalette } from "@/consts/colors";

// const institutions1 = [{ name: "HITE_JINRO" }, { name: "CAPSTONE" }, { name: "KOSME" }];
// const institutions2 = [{ name: "MARU" }, { name: "TIPS" }, { name: "SCHMIDIT" }];
// const institutions3 = [{ name: "ASAN" }, { name: "KINGSLEY" }, { name: "CAPSTONE" }];

const institutions1 = [{ name: "HITE_JINRO" }, { name: "KODIT" }, { name: "KOSME" }];
const institutions2 = [{ name: "MARU" }, { name: "TIPS" }, { name: "SCHMIDIT" }];
const institutions3 = [{ name: "ASAN" }, { name: "KINGSLEY" }, { name: "CAPSTONE" }];

const Section12 = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const is600 = useMediaQuery(sixHundMaxWidthMediaQuery);
    const is1000 = useMediaQuery(tenHundMaxWidthMediaQuery);

    return (
        <LandingLayout
            sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                paddingY: is1000 ? "80px" : "120px",
                // paddingY: isMobile || is600 ? "80px" : "120px",
                paddingX: is1000 ? "30px" : "322px",
                // paddingX: isMobile || is600 ? "20px" : "322px",
                gap: isMobile ? "40px" : "70px",
                maxWidth: "1200px",
                margin: "auto",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant={isMobile ? "f28B" : "f36B"}
                    sx={{
                        color: tmrColorPalette.grey[900],
                        whiteSpace: "pre-wrap",
                        letterSpacing: "-0.6px",
                        lineHeight: 1.3,
                    }}
                >
                    {isMobile
                        ? "공신력 있는 기관들로부터\n기술력을 검증 받았어요"
                        : "공신력 있는 기관들과 함께해요"}
                </Typography>
                <Typography
                    variant={isMobile ? "f16M" : "f22M"}
                    sx={{
                        color: tmrColorPalette.grey[400],
                        whiteSpace: "pre-wrap",
                        pt: "20px",
                        lineHeight: 1.3,
                        letterSpacing: "-0.4px",
                        ...(isMobile && {
                            lineHeight: 1.5,
                        }),
                    }}
                >
                    {isMobile ? "저희는 도망가지 않아요" : "저희는 도망가지 않아요"}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "30px",
                    width: is1000 ? "auto" : "795px",
                    maxWidth: "100vw",
                    justifyContent: "center",

                    ...(isMobile && {
                        width: "100%",
                        gap: "15px",
                    }),
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",

                        ...(isMobile && {
                            gap: "15px",
                        }),
                    }}
                >
                    {institutions1.map(({ name }) => (
                        <Box
                            key={name}
                            sx={{
                                height: isMobile ? "64px" : is600 ? "auto" : "80px",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={`/assets/images/institutions/${name}.webp`}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                }}
                                alt=""
                            />
                        </Box>
                    ))}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",

                        ...(isMobile && {
                            gap: "15px",
                        }),
                    }}
                >
                    {institutions2.map(({ name }) => (
                        <Box
                            key={name}
                            sx={{
                                height: isMobile ? "64px" : is600 ? "auto" : "80px",

                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={`/assets/images/institutions/${name}.webp`}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                }}
                                alt=""
                            />
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",

                        ...(isMobile && {
                            gap: "15px",
                        }),
                    }}
                >
                    {institutions3.map(({ name }) => (
                        <Box
                            key={name}
                            sx={{
                                height: isMobile ? "64px" : is600 ? "auto" : "80px",

                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={`/assets/images/institutions/${name}.webp`}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                }}
                                alt=""
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        </LandingLayout>
    );
};

export default Section12;
