import React from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";

import { mobileMaxWidthMediaQuery, sixHundMaxWidthMediaQuery } from "@taghere/common-utils";

import LandingLayout from "@/components/landing/v1/LandingLayout";
import LogoSlide from "@/components/landing/v1/LogoSlide";
import { tmrColorPalette } from "@/consts/colors";

const Section2 = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const is600 = useMediaQuery(sixHundMaxWidthMediaQuery);

    const brands1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const brands2 = [11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    const brands3 = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30];

    return (
        <LandingLayout
            sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                paddingX: 0,
                marginY: 0,
            }}
        >
            <Typography
                variant={isMobile ? "f28B" : "f36B"}
                sx={{
                    whiteSpace: "pre-line",
                    color: tmrColorPalette.grey[900],
                    lineHeight: 1.5,
                    letterSpacing: "-0.6px",
                    ...(isMobile && {
                        lineHeight: 1.3,
                        letterSpacing: "-0.6px",
                    }),
                }}
            >
                {isMobile
                    ? "최고의 브랜드들과\n빠르게 변화하는\n방향에 동참하세요"
                    : "최고의 브랜드들과\n빠르게 변화하는 방향에 동참하세요"}
            </Typography>

            <Box sx={{ height: isMobile ? "20px" : "16px" }} />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant={isMobile ? "f16M" : "f22M"}
                    sx={{
                        color: tmrColorPalette.grey[400],
                        whiteSpace: "pre",
                        lineHeight: "130%",
                        display: "flex",
                        letterSpacing: "-0.4px",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        ...(isMobile && {
                            lineHeight: 1.5,
                        }),
                    }}
                >
                    태그히어를 경험한{" "}
                </Typography>
                <Typography
                    variant={isMobile ? "f16B" : "f22B"}
                    sx={{
                        color: tmrColorPalette.primary[500],
                        whiteSpace: "pre",
                        lineHeight: "130%",
                        display: "flex",
                        ...(isMobile && {
                            lineHeight: 1.5,
                        }),
                    }}
                >
                    200만+ 명
                </Typography>
                <Typography
                    variant={isMobile ? "f15M" : "f22M"}
                    sx={{
                        color: tmrColorPalette.grey[400],
                        whiteSpace: "pre",
                        lineHeight: "130%",
                        display: "flex",
                        letterSpacing: "-0.4px",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        ...(isMobile && {
                            lineHeight: 1.5,
                        }),
                    }}
                >
                    이 그 증거입니다
                </Typography>
            </Box>
            <Box
                sx={{
                    marginTop: "60px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <LogoSlide
                    imgs={brands1.map((logo) => `/assets/images/lp/new_logos/${logo}.png`)}
                    duration={40}
                    imgSize={isMobile ? 80 : is600 ? 120 : 160}
                    repeatCount={isMobile ? 3 : 4}
                    imgStyle={{
                        border: `1px solid ${tmrColorPalette.grey[200]}`,
                        borderRadius: isMobile ? "10px" : "20px",
                    }}
                    padding={isMobile ? 8 : 16}
                />
                <LogoSlide
                    imgs={brands2.map((logo) => `/assets/images/lp/new_logos/${logo}.png`)}
                    duration={45}
                    imgSize={isMobile ? 80 : is600 ? 120 : 160}
                    repeatCount={isMobile ? 3 : 4}
                    reverse={"reverse"}
                    imgStyle={{
                        border: `1px solid ${tmrColorPalette.grey[200]}`,
                        borderRadius: isMobile ? "10px" : "20px",
                    }}
                    padding={isMobile ? 8 : 16}
                />
                <LogoSlide
                    imgs={brands3.map((logo) => `/assets/images/lp/new_logos/${logo}.png`)}
                    duration={40}
                    imgSize={isMobile ? 80 : is600 ? 120 : 160}
                    repeatCount={isMobile ? 3 : 4}
                    imgStyle={{
                        border: `1px solid ${tmrColorPalette.grey[200]}`,
                        borderRadius: isMobile ? "10px" : "20px",
                    }}
                    padding={isMobile ? 8 : 16}
                />

                {/* {isMobile && (
                    <LogoSlide
                        imgs={brands4.map((logo) => `/assets/images/lp/new_logos/${logo}.png`)}
                        duration={45}
                        imgSize={isMobile ? 80 : 160}
                        repeatCount={isMobile ? 3 : 4}
                        reverse={"reverse"}
                        imgStyle={{
                            border: `1px solid ${tmrColorPalette.grey[200]}`,
                            borderRadius: isMobile ? "10px" : "20px",
                        }}
                        padding={isMobile ? 8 : 16}
                    />
                )} */}
            </Box>
        </LandingLayout>
    );
};

export default Section2;
