import React from "react";

import { Button, Fade, Typography, useMediaQuery } from "@mui/material";

import { mobileMaxWidthMediaQuery } from "@taghere/common-utils";

import { tmrColorPalette } from "@/consts/colors";

import { TAGHERE_TALLY_BUTTON } from "../../consts/ui";

interface FloatingButtonProps {
    isShowing: boolean;
    tallyLink: string;
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ isShowing, tallyLink }) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    return (
        <>
            {isShowing && isMobile && (
                <Fade in timeout={1000} style={{ transitionDelay: "500ms" }}>
                    <Button
                        id={"goTallyButton"}
                        variant="contained"
                        sx={{
                            p: 3,
                            position: "fixed",
                            bottom: "30px",
                            left: "50%",
                            display: "inline-flex",
                            transform: "translateX(-50%)",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            backgroundColor: tmrColorPalette.primary[500],
                            zIndex: 99999,
                            padding: "20px 15px",
                            mt: 6,
                            borderRadius: "100px",
                            width: "173px",
                            // height: "54px",
                            opacity: scrollY,
                            ...(isMobile && {
                                height: "54px",
                                maxWidth: "186px",
                            }),
                        }}
                        href={tallyLink}
                    >
                        <Typography
                            id={"goTallyButton"}
                            variant={"f18B"}
                            sx={{
                                lineHeight: "24px",
                                color: tmrColorPalette.grey[900],
                            }}
                        >
                            {TAGHERE_TALLY_BUTTON}
                        </Typography>
                    </Button>
                </Fade>
            )}
        </>
    );
};

export default FloatingButton;
