import React from "react";
import Box from "@mui/material/Box";

interface LogoSlideProps {
    /**
     * 이미지 링크들
     */
    imgs: string[];
    /**
     * 이미지 한 텀 슬라이드 시간 - s 단위
     */
    duration?: number;
    /**
     * 정사각형 이미지 크기 - px 단워
     */
    imgSize?: number;
    /**
     * 이미지 패딩 값 - px 단워
     */
    padding?: number;
    /**
     * 동일한 이미지 리스트 반복 횟수
     */
    repeatCount?: number;
    /**
     * 반대로 슬라이드
     */
    reverse?: "reverse";
    isReverse?: boolean;
    imgStyle?: React.CSSProperties;
}

const LogoSlide: React.FC<LogoSlideProps> = ({
    imgs = [],
    duration = 50,
    imgSize = 120,
    padding = 16,
    repeatCount = 2,
    reverse = "",
    isReverse = false,
    imgStyle = {},
}) => {
    return (
        <Box
            sx={{
                "@keyframes scroll": {
                    "0%": {
                        transform: isReverse
                            ? `translateX(-${(imgSize + padding * 2) * imgs.length}px)`
                            : "translateX(0)",
                    },
                    "100%": {
                        transform: isReverse
                            ? "translateX(0)"
                            : `translateX(-${(imgSize + padding * 2) * imgs.length}px)`,
                    },
                },
                background: "white",
                m: "auto",
                overflow: "hidden",
                position: "relative",
                width: "100%",
                "::before": {
                    background:
                        "linear-gradient(to right,white 0%,rgba(255, 255, 255, 0) 100%)",
                    content: '""',
                    height: "100%",
                    position: "absolute",
                    width: "50px",
                    zIndex: 2,
                    left: 0,
                    top: 0,
                },
                "::after": {
                    background:
                        "linear-gradient(to right,white 0%,rgba(255, 255, 255, 0) 100%)",
                    content: '""',
                    height: "100%",
                    position: "absolute",
                    width: "50px",
                    zIndex: 2,
                    right: 0,
                    top: 0,
                    transform: "rotateZ(180deg)",
                },
            }}
        >
            <Box
                sx={{
                    animation: `scroll ${duration}s linear infinite ${reverse} `,
                    display: "flex",
                    width: `${(imgSize + padding * 2) * imgs.length * repeatCount}px`,
                }}
            >
                {...Array(repeatCount)
                    .fill(imgs)
                    .flat()
                    .map((imgSrc, idx) => (
                        <Box
                            key={imgSrc + idx.toString()}
                            sx={{
                                height: `${imgSize + padding * 2}px`,
                                width: `${imgSize + padding * 2}px`,
                                p: `${padding}px`,
                            }}
                        >
                            <img
                                src={`${imgSrc}`}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                    objectFit: "cover",
                                    borderRadius: "20px",
                                    ...imgStyle,
                                }}
                            />
                        </Box>
                    ))}
            </Box>
        </Box>
    );
};

export default LogoSlide;
