import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const ArrayCircleRight = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
            >
                <circle cx="20" cy="20" r="19" fill="white" stroke="#1F2462" strokeWidth="2" />
                <path
                    d="M12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21L12 19ZM28.7071 20.7071C29.0976 20.3166 29.0976 19.6834 28.7071 19.2929L22.3431 12.9289C21.9526 12.5384 21.3195 12.5384 20.9289 12.9289C20.5384 13.3195 20.5384 13.9526 20.9289 14.3431L26.5858 20L20.9289 25.6569C20.5384 26.0474 20.5384 26.6805 20.9289 27.0711C21.3195 27.4616 21.9526 27.4616 22.3431 27.0711L28.7071 20.7071ZM12 21L28 21L28 19L12 19L12 21Z"
                    fill="#131651"
                />
            </svg>
        </SvgIcon>
    );
};

export default ArrayCircleRight;
