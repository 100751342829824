import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const AndroidIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
            >
                <path
                    d="M30 16.2286L33 9.98571C33.3333 9.65714 33 9 32.3333 9C32 9 32 9 31.6667 9.32857L28.6667 15.5714C23.6667 13.2714 17 12.9429 11.3333 15.5714L8 9.32857C8 9 7.66667 9 7.33333 9C7 9 6.66667 9.65714 7 9.98571L10 16.2286C4 19.5143 0 24.7714 0 32H40C40 24.7714 36 19.5143 30 16.2286ZM11.6667 25.4286C10.6667 25.4286 10 24.7714 10 23.7857C10 22.8 10.6667 22.1429 11.6667 22.1429C12.6667 22.1429 13.3333 22.8 13.3333 23.7857C13.3333 24.7714 12.6667 25.4286 11.6667 25.4286ZM28.3333 25.4286C27.3333 25.4286 26.6667 24.7714 26.6667 23.7857C26.6667 22.8 27.3333 22.1429 28.3333 22.1429C29.3333 22.1429 30 22.8 30 23.7857C30 24.7714 29.3333 25.4286 28.3333 25.4286Z"
                    fill="black"
                />
            </svg>
        </SvgIcon>
    );
};

export default AndroidIcon;
