import React, { useEffect, useRef, useState } from "react";

import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";

import {
    mobileMaxWidthMediaQuery,
    sixHundMaxWidthMediaQuery,
    tabletMaxWidthMediaQuery,
    tenHundMaxWidthMediaQuery,
} from "@taghere/common-utils";

import LandingLayout from "@/components/landing/v1/LandingLayout";
import { tmrColorPalette } from "@/consts/colors";

import AndroidIcon from "../../icons/AndroidIcon";
import AppleIcon from "../../icons/AppleIcon";
import ArrayCircleLeft from "../../icons/ArrayCircleLeft";
import ArrayCircleRight from "../../icons/ArrayCircleRight";
import XDownloadIcon from "../../icons/XDownloadIcon";
import XHumanIcon from "../../icons/XHumanIcon";
import ZapOffIcon from "../../icons/ZapOffIcon";

const Section5 = () => {
    const isTenHundread = useMediaQuery(tenHundMaxWidthMediaQuery);
    const isTablet = useMediaQuery(tabletMaxWidthMediaQuery);
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const is600 = useMediaQuery(sixHundMaxWidthMediaQuery);
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

    const scrollBoxRef = useRef<HTMLDivElement>(null);

    const onIconButtonClick = (isScrolledToEnd: boolean) => {
        if (!scrollBoxRef.current) return;

        scrollBoxRef.current.scrollTo({
            left: isScrolledToEnd ? 0 : scrollBoxRef.current.scrollWidth,
            behavior: "smooth",
        });
    };

    const blocks = [
        {
            key: "어플리케이션 다운로드",
            text: "어플리케이션\n다운로드",
            icon: (
                <XDownloadIcon
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    sx={{
                        fontSize: "40px",
                    }}
                />
            ),
        },
        {
            key: "로그인 또는 회원가입",
            text: "로그인 또는\n회원가입",

            icon: (
                <XHumanIcon
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    sx={{
                        fontSize: "40px",
                    }}
                />
            ),
        },
        {
            key: "휴대폰 기종 상관없이",
            text: "휴대폰 기종\n상관없이",

            icon: (
                <Box
                    sx={{
                        display: "flex",
                        gap: 2,
                        marginRight: "-10px",
                    }}
                >
                    <AndroidIcon
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        sx={{
                            fontSize: "40px",
                        }}
                    />
                    <AppleIcon
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        sx={{
                            fontSize: "40px",
                        }}
                    />
                </Box>
            ),
        },
        {
            key: "전기 공사 보조배터리",
            text: "전기 공사\n보조배터리",

            icon: (
                <ZapOffIcon
                    sx={{
                        fontSize: "40px",
                    }}
                />
            ),
        },
    ];

    const handleScroll = (e: any) => {
        e.stopPropagation();
        if (!e.target) return;
        if (e.target.scrollLeft <= 10) {
            setIsScrolledToEnd(() => false);
            return;
        }
        if (e.target.scrollLeft + e.target.offsetWidth >= 580) {
            setIsScrolledToEnd(() => true);
            return;
        }
    };

    useEffect(() => {
        if (scrollBoxRef.current === null) return;

        scrollBoxRef.current.addEventListener("scroll", handleScroll);
    }, []);

    return (
        <LandingLayout
            sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: isMobile ? "left" : "center",

                alignItems: "center",
                paddingX: isMobile ? 6 : 20,
                paddingTop: isMobile ? 16 : 20,
                paddingBottom: isMobile ? 8 : 20,
                backgroundColor: tmrColorPalette.white[100],
                // maxWidth: "100vw",
                marginY: 0,
                maxWidth: isMobile ? "100%" : "1200px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isTablet ? "column" : "row",
                    textAlign: isMobile ? "left" : "center",
                    width: isMobile ? "100%" : "1200px",
                    alignItems: "center",
                    maxWidth: "100vw",
                    gap: isMobile ? 12 : 16,
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: isTablet && !isMobile ? "center" : "left",
                        gap: isMobile ? "16px" : 4,
                    }}
                >
                    <Typography
                        variant={isMobile ? "f28B" : "f36B"}
                        sx={{
                            whiteSpace: "pre-line",
                            color: tmrColorPalette.grey[900],
                            lineHeight: 1.5,
                            ...(isMobile && {
                                lineHeight: 1.3,
                                letterSpacing: "-0.6px",
                            }),
                        }}
                    >
                        편하게 태그만
                    </Typography>
                    <Typography
                        variant={isMobile ? "f16M" : "f22M"}
                        sx={{
                            color: tmrColorPalette.grey[400],
                            lineHeight: isMobile ? 1.5 : 1.3,
                            letterSpacing: "-0.4px",
                            whiteSpace: "pre-line",
                        }}
                    >
                        {
                            "손님 휴대폰 기종 관계 없이, 앱과 로그인 필요 없이,\n모두 사용 가능한 테이블 오더 시스템"
                        }
                    </Typography>
                </Box>

                <Box
                    ref={scrollBoxRef}
                    sx={{
                        width: isMobile || is600 ? "100vw" : "auto",
                        overflow: "scroll",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            gap: "10px",
                            width: is600
                                ? `${blocks.length * 150 + 40 + (blocks.length + 2 - 1) * 10}px`
                                : "auto",
                        }}
                    >
                        {(isMobile || is600) && <Box sx={{ paddingLeft: "20px" }} />}
                        {blocks.map((block, idx) => (
                            <Box
                                key={`${block.key}-${idx}`}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "210px",
                                    maxWidth: "148px",
                                    width: "148px",
                                    padding: "20px",
                                    borderRadius: "10px",
                                    backgroundColor: tmrColorPalette.secondary[100],
                                    border: `1px solid ${tmrColorPalette.secondary[200]}`,
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    variant="f14B"
                                    sx={{
                                        color: tmrColorPalette.grey[900],
                                        lineHeight: 1.3,
                                        textAlign: "left",
                                        whiteSpace: "pre-line",
                                    }}
                                >
                                    {block.text}
                                </Typography>
                                <Box
                                    sx={{
                                        height: "80px",
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "end",
                                        alignItems: "end",
                                    }}
                                >
                                    {block.icon}
                                </Box>
                            </Box>
                        ))}
                        {(isMobile || is600) && <Box sx={{ paddingLeft: "20px" }} />}
                    </Box>
                </Box>
            </Box>
            {isMobile && (
                <Box
                    sx={{
                        width: "100%",
                        alignItems: "right",
                        display: "flex",
                        justifyContent: "right",
                        marginTop: "30px",
                    }}
                >
                    <IconButton
                        onClick={() => onIconButtonClick(isScrolledToEnd)}
                        sx={{
                            padding: 0,
                        }}
                    >
                        {isScrolledToEnd ? (
                            <ArrayCircleLeft
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                sx={{
                                    fontSize: "40px",
                                }}
                            />
                        ) : (
                            <ArrayCircleRight
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                sx={{
                                    fontSize: "40px",
                                }}
                            />
                        )}
                    </IconButton>
                </Box>
            )}
        </LandingLayout>
    );
};

export default Section5;
