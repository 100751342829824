import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const AppleIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
            >
                <path
                    d="M35 28.4114C33.8603 31.8315 30.4485 37.8872 26.9337 37.9532C24.6021 37.9997 23.8524 36.5236 21.1873 36.5236C18.5236 36.5236 17.6899 37.9082 15.4859 37.9982C11.7565 38.1467 6 29.2574 6 21.5051C6 14.3844 10.7966 10.8548 14.9871 10.7903C17.2346 10.7483 19.3574 12.3578 20.7276 12.3578C22.1037 12.3578 24.6818 10.4228 27.3918 10.7063C28.5257 10.7558 31.7114 11.1788 33.7559 14.2719C28.3314 17.935 29.1768 25.5958 35 28.4114ZM27.4281 2C23.3304 2.17101 19.9867 6.61865 20.4536 10.2968C24.241 10.6013 27.8747 6.20914 27.4281 2Z"
                    fill="black"
                />
            </svg>
        </SvgIcon>
    );
};

export default AppleIcon;
