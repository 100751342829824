import React from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";

import {
    mobileMaxWidthMediaQuery,
    sixHundMaxWidthMediaQuery,
    tabletMaxWidthMediaQuery,
} from "@taghere/common-utils";

import PackageCheckIcon from "@/components/icons/PackageCheckIcon";
import PhoneIcon from "@/components/icons/PhoneIcon";
import ZapOffIcon from "@/components/icons/ZapOffIcon";
import LandingLayout from "@/components/landing/v1/LandingLayout";
import { tmrColorPalette } from "@/consts/colors";

const Section2_3 = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const istablet = useMediaQuery(tabletMaxWidthMediaQuery);
    const is600 = useMediaQuery(sixHundMaxWidthMediaQuery);

    const blocks = [
        {
            title: "연중무휴 고객센터 운영",
            desc: "태그히어는 주말 및 공휴일 모두\n상담이 가능합니다\n운영시간 : 오전 9시~ 새벽1시",
            icon: <PhoneIcon />,
        },
        {
            title: "기기가 고장나지 않아요",
            desc: "전기가 필요 없어서 관리하기 편해요\n물이나 알코올이 닿아도 괜찮아요",

            icon: <ZapOffIcon />,
        },
        {
            title: "과정이 심플해요",
            desc: "제품이 고장나도 복잡한 재설치 없이\n제품 수령 후 1분 안에 해결이 가능해요",
            icon: <PackageCheckIcon />,
        },
    ];

    return (
        <LandingLayout
            sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: isMobile ? "left" : "center",
                width: isMobile ? "100%" : "1200px",
                maxWidth: "100vw",
                marginY: 0,
                paddingY: isMobile ? 16 : 20,
                paddingX: istablet ? "30px" : "auto",
            }}
        >
            <Typography
                variant={isMobile ? "f28B" : "f36B"}
                sx={{
                    whiteSpace: "pre-line",
                    lineHeight: 1.5,
                    color: tmrColorPalette.grey[900],
                    ...(isMobile && {
                        lineHeight: 1.3,
                        letterSpacing: "-0.6px",
                    }),
                }}
            >
                {isMobile
                    ? "매장 운영에만\n집중할 수 있어요"
                    : "매장 운영에만 집중할 수 있어요"}
            </Typography>
            <Box
                sx={{
                    paddingTop: isMobile ? 10 : 12,
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: isMobile || is600 ? "column" : "row",
                    gap: "20px",
                    maxWidth: "100%",
                }}
            >
                {blocks.map((block, idx) => (
                    <Box
                        key={`${block.title}-${block.desc}-${idx}`}
                        data-aos="zoom-in"
                        data-aos-delay={`${isMobile ? 0 : 200 * idx}`}
                        sx={{
                            flex: 1,
                            boxSizing: "border-box",
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: tmrColorPalette.white[100],
                                border: `1px solid ${tmrColorPalette.grey[200]}`,
                                borderRadius: 4,
                                padding: 6,
                                display: "flex",
                                gap: 4,
                                flexDirection: "column",
                                justifyContent: "left",
                                alignItems: "left",
                                textAlign: "left",
                                height: "100%",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "20px",
                                    backgroundColor: tmrColorPalette.secondary[100],
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {block.icon}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: isMobile ? "13px" : 2,
                                }}
                            >
                                <Typography
                                    variant="f20B"
                                    sx={{
                                        lineHeight: 1.3,
                                        letterSpacing: "-0.4px",
                                        color: tmrColorPalette.grey[900],
                                    }}
                                >
                                    {block.title}
                                </Typography>
                                <Typography
                                    variant="f15M"
                                    sx={{
                                        color: tmrColorPalette.grey[600],
                                        lineHeight: 1.5,
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    {block.desc}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </LandingLayout>
    );
};

export default Section2_3;
