import React from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";

import { mobileMaxWidthMediaQuery } from "@taghere/common-utils";

import LandingLayout from "@/components/landing/v1/LandingLayout";
import { tmrColorPalette } from "@/consts/colors";

const Section11 = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

    return (
        <LandingLayout
            sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                paddingY: isMobile ? "286px" : "100px",
                paddingX: isMobile ? "40px" : "auto",
                backgroundColor: tmrColorPalette.black[100],
                maxWidth: "100vw",
                marginY: 0,
            }}
        >
            <Box
                data-aos="fade-up"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                }}
            >
                <Box>
                    <img src="./assets/images/icons/shieldWithLock.svg" alt="" />
                </Box>
                <Typography
                    variant={isMobile ? "f28B" : "f36B"}
                    sx={{
                        color: tmrColorPalette.white[100],
                        whiteSpace: "pre-wrap",
                        paddingTop: "24px",
                        paddingBottom: "20px",
                        lineHeight: 1.3,
                        letterSpacing: "-0.6px",
                    }}
                >
                    {isMobile
                        ? "QR코드와 다르게\n보안의 위험이 없어요"
                        : "QR코드와 다르게 보안의 위험이 없어요"}
                </Typography>
                <Typography
                    variant={isMobile ? "f16M" : "f20M"}
                    sx={{
                        color: isMobile ? tmrColorPalette.grey[200] : tmrColorPalette.grey[400],
                        whiteSpace: "pre-wrap",
                        lineHeight: 1.3,
                        letterSpacing: "-0.4px",
                    }}
                >
                    {
                        "블로그에 올라간 우리 매장 큐알코드,\n외부에서 악의적인 주문이 가능하다는것 아셨나요?"
                    }
                </Typography>
            </Box>
        </LandingLayout>
    );
};

export default Section11;
