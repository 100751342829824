import React, { useState } from "react";

import { Box, Button, Typography, useMediaQuery } from "@mui/material";

import {
    mobileMaxWidthMediaQuery,
    nineHundMaxWidthMediaQuery,
    sixHundMaxWidthMediaQuery,
    tabletMaxWidthMediaQuery,
    tenHundMaxWidthMediaQuery,
} from "@taghere/common-utils";

import LandingLayout from "@/components/landing/v1/LandingLayout";
import { tmrColorPalette } from "@/consts/colors";

const selectingSections = [
    {
        buttonTitle: "화이트",
        imageUrl1: "/assets/images/lp/section4_1/white1",
        imageUrl2: "/assets/images/lp/section4_1/white2",
        imageUrl3: "/assets/images/lp/section4_1/white3",
        imageUrl4: "/assets/images/lp/section4_1/white4",
    },
    {
        buttonTitle: "블랙",
        imageUrl1: "/assets/images/lp/section4_1/black1",
        imageUrl2: "/assets/images/lp/section4_1/black2",
        imageUrl3: "/assets/images/lp/section4_1/black3",
        imageUrl4: "/assets/images/lp/section4_1/black4",
    },
];

const Section4_1 = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const is1000 = useMediaQuery(tenHundMaxWidthMediaQuery);
    const istablet = useMediaQuery(tabletMaxWidthMediaQuery);
    const is600 = useMediaQuery(sixHundMaxWidthMediaQuery);

    const [selectedIndex, setSelectedIndex] = useState(0);
    return (
        <LandingLayout
            sx={{
                textAlign: "center",
                marginTop: "107px",
                marginBottom: "107px",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                justifyContent: "center",
                maxWidth: isMobile ? "100%" : "1200px",
                paddingY: 0,
                paddingX: is1000 || istablet ? "30px" : "0",
                gap: "31px",
            }}
        >
            <Typography
                variant={isMobile ? "f28B" : "f36B"}
                sx={{
                    lineHeight: 1.3,
                    letterSpacing: "-0.6px",
                    color: tmrColorPalette.grey[900],
                    whiteSpace: "pre-wrap",
                }}
            >
                {isMobile ? "내 매장에 맞게\n고를 수 있어요" : "내 매장에 맞게 고를 수 있어요"}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "30px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: isMobile || is600 ? "10px" : "20px",
                    }}
                >
                    {selectingSections.map((selectingSection, idx) => (
                        <Box
                            onClick={() => {
                                setSelectedIndex(idx);
                            }}
                            key={selectingSection.buttonTitle}
                            sx={{
                                width: "100px",
                                padding: "12px 20px",
                                borderRadius: "27.5px",
                                alignItems: "center",
                                textAlign: "top",
                                fontSize: "22px",
                                cursor: "pointer",

                                ...(idx === selectedIndex && {
                                    backgroundColor: tmrColorPalette.grey[100],
                                }),
                                ...(isMobile && {
                                    width: "82px",
                                    fontSize: "16px",
                                }),
                            }}
                        >
                            <Typography
                                variant={isMobile ? "f16SB" : "f22B"}
                                sx={{
                                    color: tmrColorPalette.grey[200],
                                    lineHeight: 1.3,
                                    letterSpacing: "-0.4px",
                                    ...(idx === selectedIndex && {
                                        color: tmrColorPalette.secondary[700],
                                    }),
                                }}
                            >
                                {selectingSection.buttonTitle}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        gap: "20px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            gap: "20px",
                            flex: 1,
                        }}
                    >
                        <img
                            src={`${selectingSections[selectedIndex].imageUrl1}${
                                isMobile ? "_mo" : ""
                            }.png`}
                            style={{
                                height: isMobile ? "480px" : "auto",
                                flex: 1,
                                maxWidth: "100%",
                                width: "100%",
                                borderRadius: "20px",
                            }}
                        />
                        <img
                            src={`${selectingSections[selectedIndex].imageUrl2}${
                                isMobile ? "_mo" : ""
                            }.png`}
                            style={{
                                height: isMobile ? "480px" : "auto",
                                flex: 1,
                                maxWidth: "100%",
                                width: "100%",
                                borderRadius: "20px",
                            }}
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            gap: "20px",
                            flex: 1,
                        }}
                    >
                        <img
                            src={`${selectingSections[selectedIndex].imageUrl3}${
                                isMobile ? "_mo" : ""
                            }.png`}
                            style={{
                                height: isMobile ? "480px" : "auto",
                                flex: 1,
                                maxWidth: "100%",
                                width: "100%",
                                borderRadius: "20px",
                            }}
                        />
                        <img
                            src={`${selectingSections[selectedIndex].imageUrl4}${
                                isMobile ? "_mo" : ""
                            }.png`}
                            style={{
                                height: isMobile ? "480px" : "auto",
                                flex: 1,
                                maxWidth: "100%",
                                width: "100%",
                                borderRadius: "20px",
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </LandingLayout>
    );
};

export default Section4_1;
