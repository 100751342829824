import React, { useState } from "react";

import { Box, Button, Typography, useMediaQuery } from "@mui/material";

import {
    mobileMaxWidthMediaQuery,
    sixHundMaxWidthMediaQuery,
    tenHundMaxWidthMediaQuery,
    threeHundSevenMaxWidthMediaQuery,
} from "@taghere/common-utils";

import LandingLayout from "@/components/landing/v1/LandingLayout";
import { tmrColorPalette } from "@/consts/colors";

const selectingSections = [
    {
        buttonTitle: "메뉴 번역",
        sectionTitle: "영어, 중국어, 일본어 모두 가능해요",
        sectionTitle_mo: "영어, 중국어, 일본어\n모두 가능해요",
        sectionDesc:
            "외국인이 와도 태그히어를 문제없이 이용할 수 있어요.\n번역은 똑똑한 AI가 알아서 해줘요",
        sectionDesc_mo:
            "외국인이 와도 태그히어를 문제없이 이용할 수 있어요. 번역은 똑똑한 AI가 알아서 해줘요",
        imageUrl: "/assets/images/lp/section4/translation.png",
    },
    {
        buttonTitle: "홍보 링크",
        sectionTitle: "우리 매장을 다양하게 홍보 할 수 있어요",
        sectionTitle_mo: "우리 매장을 다양하게\n홍보 할 수 있어요",
        sectionDesc:
            "인스타그램 바로가기 기능, 네이버 영수증 바로가기\n기능으로 매장 마케팅에 도움이 돼요",
        sectionDesc_mo:
            "인스타그램 바로가기 기능,\n네이버 영수증 바로가기\n기능으로 매장 마케팅에 도움이 돼요",
        imageUrl: "/assets/images/lp/section4/promotion.png",
    },
    {
        buttonTitle: "메뉴 수정",
        sectionTitle: "언제 어디서나\n메뉴판 수정이 가능해요",
        sectionTitle_mo: "언제 어디서나\n메뉴판 수정이 가능해요",
        sectionDesc:
            "메뉴 추가, 수정 및 품절처리나 숨김처리로\n실제 매장 운영과 맞출 수 있어요",
        sectionDesc_mo:
            "메뉴 추가, 수정 및 품절처리나 숨김처리로\n실제 매장 운영과 맞출 수 있어요",
        sectionDetail: "*PC, 휴대폰, 포스, 태블릿 모두 가능",
        imageUrl: "/assets/images/lp/section4/menuedit.png",
    },
];

const Section4 = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const is600 = useMediaQuery(sixHundMaxWidthMediaQuery);
    const is1000 = useMediaQuery(tenHundMaxWidthMediaQuery);
    const is370 = useMediaQuery(threeHundSevenMaxWidthMediaQuery);

    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <LandingLayout
            sx={{
                textAlign: "left",
                paddingTop: "100px",
                paddingBottom: "100px",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                justifyContent: "center",
                maxWidth: isMobile ? "100%" : "996px",
                paddingX: is1000 ? "30px" : "0",
                gap: isMobile ? "30px" : "20px",
                marginTop: 0,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: isMobile || is600 ? "10px" : "20px",
                    flexWrap: "wrap",
                }}
            >
                {selectingSections.map((selectingSection, idx) => (
                    <Box
                        onClick={() => setSelectedIndex(idx)}
                        key={selectingSection.buttonTitle}
                        sx={{
                            width: "120px",
                            padding: "12px 20px",
                            borderRadius: "27.5px",
                            alignItems: "center",
                            textAlign: "top",
                            fontSize: "22px",
                            cursor: "pointer",
                            ...(idx === selectedIndex && {
                                backgroundColor: tmrColorPalette.grey[100],
                            }),
                            ...(isMobile && {
                                width: "100px",
                                fontSize: "16px",
                            }),
                        }}
                    >
                        <Typography
                            variant={isMobile ? "f16SB" : "f22B"}
                            sx={{
                                color: tmrColorPalette.grey[200],
                                lineHeight: 1.3,
                                letterSpacing: "-0.4px",
                                ...(idx === selectedIndex && {
                                    color: tmrColorPalette.secondary[700],
                                }),
                            }}
                        >
                            {selectingSection.buttonTitle}
                        </Typography>
                    </Box>
                ))}
            </Box>
            <Box
                sx={{
                    height: "500px",
                    width: "100%",
                    backgroundColor: tmrColorPalette.background[100],
                    borderRadius: "20px",
                    display: "flex",
                    padding: "20px 50px",
                    justifyContent: "space-between",
                    ...((isMobile || is600) && {
                        flexDirection: "column",
                        textAlign: "left",
                        padding: "30px",
                        height: "575px",
                        alignItems: "center",
                    }),
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "left",
                        width: "100%",
                        cursor: "default",

                        ...(isMobile && {
                            gap: "30px",
                            textAlign: "left",
                        }),
                    }}
                >
                    <Typography
                        variant={isMobile ? "f22B" : "f24B"}
                        sx={{
                            color: tmrColorPalette.grey[900],
                            lineHeight: 1.3,
                            letterSpacing: "-0.4px",
                            whiteSpace: "pre-line",
                            width: "100%",
                        }}
                    >
                        {isMobile
                            ? selectingSections[selectedIndex].sectionTitle_mo
                            : selectingSections[selectedIndex].sectionTitle}
                    </Typography>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                        }}
                    >
                        <Typography
                            variant="f16M"
                            sx={{
                                color: tmrColorPalette.grey[400],
                                lineHeight: 1.5,
                                whiteSpace: "pre-line",
                                width: "100%",
                            }}
                        >
                            {isMobile
                                ? selectingSections[selectedIndex].sectionDesc_mo
                                : selectingSections[selectedIndex].sectionDesc}
                        </Typography>
                        {selectingSections[selectedIndex].sectionDetail && (
                            <Typography
                                variant="f16M"
                                sx={{
                                    color: tmrColorPalette.grey[400],
                                    lineHeight: 1.5,
                                    whiteSpace: "pre-line",
                                    width: "100%",
                                }}
                            >
                                {selectingSections[selectedIndex].sectionDetail}
                            </Typography>
                        )}
                    </Box>
                </Box>

                <img
                    src={selectingSections[selectedIndex].imageUrl}
                    style={{
                        width: "460px",
                        height: "460px",
                        ...(is600 && {
                            width: "400px",
                            height: "400px",
                        }),
                        ...(isMobile && {
                            width: "315px",
                            height: "315px",
                        }),
                    }}
                />
            </Box>
        </LandingLayout>
    );
};

export default Section4;
