import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";

import { Box, Button, Typography, useMediaQuery } from "@mui/material";

import {
    fourHundMaxWidthMediaQuery,
    mobileMaxWidthMediaQuery,
    tabletMaxWidthMediaQuery,
} from "@taghere/common-utils";

import { tmrColorPalette } from "@/consts/colors";

import LandingLayout from "../v1/LandingLayout";

interface CarouselDotsProps {
    carouselIdx: number;
    onChange: (idx: number) => void;
    blocks: { title: string; imageUrl: string }[];
}

const CarouselDots = ({ carouselIdx, onChange, blocks }: CarouselDotsProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                gap: "12px",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {blocks.map(
                (block, idx) =>
                    Math.abs(carouselIdx - idx) <
                        (carouselIdx === 0 || carouselIdx === blocks.length - 1 ? 4 : 3) && (
                        <Box
                            key={idx}
                            onClick={() => onChange(idx)}
                            sx={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: tmrColorPalette.grey[100],
                                borderRadius: "100px",

                                ...(Math.abs(carouselIdx - idx) >
                                    (carouselIdx === 0 || carouselIdx === blocks.length - 1
                                        ? 2
                                        : 1) && {
                                    width: "5px",
                                    height: "5px",
                                    backgroundColor: tmrColorPalette.grey[100],
                                    borderRadius: "100px",
                                }),

                                ...(idx === carouselIdx && {
                                    width: "20px",
                                    height: "10px",
                                    backgroundColor: tmrColorPalette.secondary[900],
                                    borderRadius: "100px",
                                }),
                            }}
                        />
                    ),
            )}
        </Box>
    );
};

const Customize = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

    const isTablet = useMediaQuery(tabletMaxWidthMediaQuery);
    const [carouselIdx, setCarouselIdx] = useState(0);

    const blocks = [
        {
            title: "하이바 광안리점",
            imageUrl: "/assets/images/customizes/custom_1",
            tagImage: "/assets/images/customizes/tag_1",
        },
        {
            title: "하이바 광안리점",
            imageUrl: "/assets/images/customizes/custom_2",
            tagImage: "/assets/images/customizes/tag_2",
        },
        {
            title: "하이바 광안리점",
            imageUrl: "/assets/images/customizes/custom_3",
            tagImage: "/assets/images/customizes/tag_3",
        },
        {
            title: "하이바 광안리점",
            imageUrl: "/assets/images/customizes/custom_4",
            tagImage: "/assets/images/customizes/tag_4",
        },
        {
            title: "하이바 광안리점",
            imageUrl: "/assets/images/customizes/custom_5",
            tagImage: "/assets/images/customizes/tag_5",
        },
        {
            title: "하이바 광안리점",
            imageUrl: "/assets/images/customizes/custom_6",
            tagImage: "/assets/images/customizes/tag_6",
        },
        {
            title: "하이바 광안리점",
            imageUrl: "/assets/images/customizes/custom_7",
            tagImage: "/assets/images/customizes/tag_7",
        },
    ];

    return (
        <LandingLayout
            sx={{
                textAlign: "center",
                marginY: isMobile ? "80px" : 0,
                width: isMobile ? "100%" : "1220px",
                maxWidth: "100vw",
                // paddingX: isTablet ? "30px" : 0,
                padding: isMobile ? "0" : "auto",
                paddingX: isMobile ? "0" : "auto",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography
                    variant={isMobile ? "f16SB" : "f20B"}
                    sx={{
                        color: tmrColorPalette.primary[500],
                        lineHeight: 1.5,
                    }}
                >
                    개성의 시대
                </Typography>
                <Box
                    sx={{
                        height: "4px",
                    }}
                />
                <Typography
                    variant={isMobile ? "f28B" : "f36B"}
                    sx={{
                        color: tmrColorPalette.grey[900],
                        lineHeight: 1.5,
                        whiteSpace: "pre-line",
                        ...(isMobile && {
                            letterSpacing: "-0.6px",
                            lineHeight: 1.3,
                        }),
                    }}
                >
                    {isMobile ? `테이블오더, \n내가 원하는대로` : "테이블오더, 내가 원하는대로"}
                </Typography>
                <Typography
                    variant={isMobile ? "f16M" : "f20M"}
                    sx={{
                        color: tmrColorPalette.grey[400],
                        lineHeight: 1.5,
                        whiteSpace: "pre-line",
                        paddingTop: "20px",

                        ...(isMobile && {
                            paddingTop: "20px",
                        }),
                    }}
                >
                    세상에 하나뿐인 우리 매장만을 위한 테이블 오더
                </Typography>
            </Box>
            <Box
                sx={{
                    paddingBottom: isMobile ? "30px" : "30px",
                }}
            />

            <Carousel
                showArrows={false}
                showIndicators={false}
                showThumbs={false}
                showStatus={false}
                selectedItem={carouselIdx}
                onChange={(idx) => setCarouselIdx(() => idx)}
                preventMovementUntilSwipeScrollTolerance
                swipeScrollTolerance={50}
                verticalSwipe={"natural"}
                emulateTouch
                infiniteLoop
                autoPlay
                interval={3000}
            >
                {blocks.map((block) =>
                    isMobile ? (
                        <Box
                            key={block.title}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingX: "30px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "400px",
                                }}
                            >
                                <img
                                    alt="customizes"
                                    src={`${block.imageUrl}_mo.png`}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                    }}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            key={block.title}
                            sx={{
                                maxWidth: "1200px",
                                margin: "auto",
                                borderRadius: "20px",
                                height: isTablet ? "auto" : "600px",
                                width: "100%",
                                paddingX: "30px",
                            }}
                        >
                            <img
                                alt="customizes"
                                src={`${block.imageUrl}.png`}
                                style={{
                                    borderRadius: "20px",
                                }}
                            />
                        </Box>
                        // <Box
                        //     key={block.title}
                        //     sx={{
                        //         display: "flex",
                        //         justifyContent: "center",
                        //         alignItems: "center",
                        //         maxWidth: "1200px",
                        //         margin: "auto",
                        //         borderRadius: "20px",
                        //         backgroundImage: `url(${block.imageUrl}.png)`,
                        //         height: "570px",
                        //         backgroundPosition: "center",
                        //         backgroundRepeat: "no-repeat",
                        //         backgroundSize: "cover",
                        //     }}
                        // >
                        //     <img
                        //         alt="customizes"
                        //         src={`${block.imageUrl}.png`}
                        //         style={{
                        //             width: "195px",
                        //             position: "absolute",
                        //             right: "50px",
                        //             bottom: "50px",
                        //         }}
                        //     />
                        //     <Box
                        //         sx={{
                        //             // maxHeight: "570px",
                        //             width: "100%",
                        //             height: "auto",

                        //             display: "flex",
                        //             justifyContent: "center",
                        //             alignItems: "center",
                        //             borderRadius: "50px",
                        //         }}
                        //     ></Box>
                        // </Box>
                    ),
                )}
            </Carousel>
            <Box
                sx={{
                    paddingTop: "30px",
                }}
            />
            <CarouselDots
                carouselIdx={carouselIdx}
                onChange={(idx) => setCarouselIdx(() => idx)}
                blocks={blocks}
            />
        </LandingLayout>
    );
};

export default Customize;
