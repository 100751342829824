import React, { useEffect, useRef, useState } from "react";

import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Button, IconButton, Typography, useMediaQuery } from "@mui/material";

import {
    mobileMaxWidthMediaQuery,
    nineHundMaxWidthMediaQuery,
    tabletMaxWidthMediaQuery,
} from "@taghere/common-utils";

import LandingLayout from "@/components/landing/v1/LandingLayout";
import { tmrColorPalette } from "@/consts/colors";

const IMAGE_SRC_BASIC = "/assets/images/lp/cards/";

const cards = [
    {
        title: "자리 차지가 적고,\n매장의 무드를 해치지 않아요",
        title_mo: "자리 차지가 적고,\n매장의 무드를 해치지 않아요",
        restaurant: "아웃닭, 본점",
        image: `${IMAGE_SRC_BASIC}card_1.png`,
        url: "https://docs.channel.io/0614/ko/articles/dfdc12e6--아웃닭--태그히어-설치-사례",
    },
    {
        title: "브랜딩, 인테리어\n측면에서 너무 좋아요",
        title_mo: "브랜딩, 인테리어\n측면에서 너무 좋아요",
        restaurant: "보어드앤헝그리, 성수",
        image: `${IMAGE_SRC_BASIC}card_2.png`,
        url: "https://docs.channel.io/0614/ko/articles/032477c7--보어드앤헝그리--태그히어-설치-사례",
    },
    {
        title: "재주문률과 사이드 메뉴\n주문 건수가 확 늘었어요",
        title_mo: "재주문률과 사이드 메뉴\n주문 건수가 확 늘었어요",
        restaurant: "서울브루어리, 성수",
        image: `${IMAGE_SRC_BASIC}card_3.png`,
        url: "https://docs.channel.io/0614/ko/articles/66db2f65--서울브루어리--태그히어-설치-사례",
    },
    {
        title: "테이블마다 직원이 있는 거 같아요",
        title_mo: "테이블마다\n직원이 있는 거 같아요",
        restaurant: "치즈웨이브, 송도",
        image: `${IMAGE_SRC_BASIC}card_4.png`,
        url: "https://docs.channel.io/0614/ko/articles/e9e7f55b--치즈웨이브--테이블마다-우리-직원이-있는-것-같아요",
    },
    {
        title: "이동 동선이 줄어들어\n다른 업무에 집중할 수 있어요",
        title_mo: "이동 동선이 줄어들어\n다른 업무에 집중할 수 있어요",
        restaurant: "eert, 망원",
        image: `${IMAGE_SRC_BASIC}card_5.png`,
        url: "https://docs.channel.io/0614/ko/articles/9daf703f--eert--태그히어-설치-사례",
    },

    {
        title: "매장 동선의 효율화는 물론\n가게 홍보에 도움이 돼요",
        title_mo: "매장 동선의 효율화는 물론\n가게 홍보에 도움이 돼요",
        restaurant: "포유, 성수",
        image: `${IMAGE_SRC_BASIC}card_6.png`,
        url: "https://docs.channel.io/0614/ko/articles/9c752327--포유-성수--태그히어-설치-사례",
    },
];
const Section2_1 = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const is900 = useMediaQuery(nineHundMaxWidthMediaQuery);

    const scrollBoxRef = useRef<HTMLDivElement>(null);

    const onLeftIconButtonClick = () => {
        if (!scrollBoxRef.current) return;
        const movingPx = isMobile ? 315 + 20 : 480 + 20;

        scrollBoxRef.current.scrollBy({
            left: -movingPx,
            behavior: "smooth",
        });
    };
    const onRightIconButtonClick = () => {
        if (!scrollBoxRef.current) return;
        const movingPx = isMobile ? 315 + 20 : 480 + 20;

        scrollBoxRef.current.scrollBy({
            left: +movingPx,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        console.log(!scrollBoxRef.current, isMobile);
        if (!scrollBoxRef.current) return;
        if (isMobile) {
            scrollBoxRef.current.scrollLeft = 30;
            return;
        }
        const scrollBoxWidth = scrollBoxRef.current.scrollWidth;
        const visibleWidth = scrollBoxRef.current.clientWidth;
        const scrollTo = (scrollBoxWidth - visibleWidth) / 2;

        scrollBoxRef.current.scrollLeft = scrollTo;
    }, [isMobile]);

    return (
        <LandingLayout
            sx={{
                textAlign: "center",
                marginY: isMobile ? "80px" : "0",
                width: isMobile ? "100%" : "auto",
                // width: isMobile ? "100%" : "1220px",
                maxWidth: "100vw",
                padding: 0,
            }}
        >
            <Typography
                variant={isMobile ? "f28B" : "f36B"}
                sx={{
                    whiteSpace: "pre-line",
                    lineHeight: 1.5,
                    color: tmrColorPalette.grey[900],
                }}
            >
                {"태그히어를 사용하고\n가게가 이렇게 변했어요"}
            </Typography>
            <Box
                sx={{
                    position: "relative",
                    marginTop: "60px",
                }}
            >
                <Box
                    ref={scrollBoxRef}
                    sx={{
                        overflowX: "scroll",
                        overflowY: "hidden",
                        height: "520px",
                        position: "relative",

                        ...(isMobile && {
                            width: "100vw",
                            height: "315px",
                        }),
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: isMobile ? "20px" : 4,
                            overflowX: "wrap",
                            width: isMobile
                                ? `${315 * 6 + 20 * 7 + 30 * 2}px`
                                : `${480 * 6 + 20 * 7 + 30 * 2}px`,
                            height: "520px",
                            ...(isMobile && {
                                height: "315px",
                            }),
                        }}
                    >
                        {isMobile && (
                            <Box
                                sx={{
                                    paddingLeft: "30px",
                                }}
                            />
                        )}
                        {cards.map(({ title, title_mo, restaurant, image, url }) => (
                            <Box
                                id={restaurant}
                                key={`${title}-${image}`}
                                onClick={() => {
                                    url && window?.open(url)?.focus();
                                }}
                                sx={{
                                    maxWidth: isMobile ? "340px" : "500px",
                                    textAlign: "left",
                                    display: "flex",
                                    height: "100%",
                                    cursor: "pointer",
                                    position: "sticky",
                                }}
                            >
                                <Box
                                    id={restaurant}
                                    sx={{
                                        position: "absolute",
                                        height: "260px",
                                        width: "100%",
                                        bottom: 0,
                                        background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)`,
                                        borderRadius: "20px",
                                        ...(isMobile && {
                                            height: "150px",
                                        }),
                                    }}
                                />
                                <Box
                                    sx={{
                                        borderRadius: "20px",
                                        width: "480px",
                                        height: "520px",
                                        marginBottom: "20px",
                                        ...(isMobile && {
                                            height: "315px",
                                            width: "315px",
                                        }),
                                    }}
                                >
                                    <img
                                        id={restaurant}
                                        src={image}
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            borderRadius: "20px",
                                        }}
                                    />
                                </Box>
                                <Box
                                    id={restaurant}
                                    sx={{
                                        position: "absolute",
                                        left: "30px",
                                        bottom: "30px",
                                        gap: "10px",
                                    }}
                                >
                                    <Typography
                                        variant={isMobile ? "f22M" : "f28M"}
                                        sx={{
                                            whiteSpace: "pre-line",
                                            lineHeight: "130%",
                                            letterSpacing: "-0.6px",
                                            color: tmrColorPalette.background[100],
                                            overflow: "hidden",
                                            display: "-webkit-box",
                                            ...(isMobile && {
                                                letterSpacing: "-0.4px",
                                            }),
                                        }}
                                    >
                                        {isMobile ? title_mo : title}
                                    </Typography>
                                    <Typography
                                        variant={"f20R"}
                                        sx={{
                                            paddingTop: "10px",
                                            whiteSpace: "pre-line",
                                            lineHeight: 1.3,
                                            letterSpacing: "-0.4px",
                                            color: tmrColorPalette.grey[200],
                                            overflow: "hidden",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "1",
                                            WebkitBoxOrient: "vertical",
                                        }}
                                    >
                                        {restaurant}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                        {isMobile && (
                            <Box
                                sx={{
                                    paddingLeft: "30px",
                                }}
                            />
                        )}
                    </Box>
                </Box>
                {!is900 && (
                    <>
                        <IconButton
                            onClick={onLeftIconButtonClick}
                            sx={{
                                padding: "7px",
                                border: `1px solid ${tmrColorPalette.grey[100]}`,
                                backgroundColor: tmrColorPalette.white[100],
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                                left: "120px",
                            }}
                        >
                            <ChevronLeft
                                sx={{
                                    width: "24px",
                                    height: "24px",
                                    color: tmrColorPalette.grey[900],
                                }}
                            />
                        </IconButton>
                        <IconButton
                            onClick={onRightIconButtonClick}
                            sx={{
                                padding: "7px",
                                border: `1px solid ${tmrColorPalette.grey[100]}`,
                                backgroundColor: tmrColorPalette.white[100],
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                                right: "120px",
                            }}
                        >
                            <ChevronRight
                                sx={{
                                    width: "24px",
                                    height: "24px",
                                    color: tmrColorPalette.grey[900],
                                }}
                            />
                        </IconButton>
                    </>
                )}
            </Box>
            {isMobile && (
                <Box
                    sx={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "end",
                        paddingX: "30px",
                        gap: "20px",
                    }}
                >
                    <IconButton
                        onClick={onLeftIconButtonClick}
                        sx={{
                            padding: "7px",
                            border: `1px solid ${tmrColorPalette.grey[100]}`,
                        }}
                    >
                        <ChevronLeft
                            sx={{
                                width: "24px",
                                height: "24px",
                                color: tmrColorPalette.grey[900],
                            }}
                        />
                    </IconButton>
                    <IconButton
                        onClick={onRightIconButtonClick}
                        sx={{
                            padding: "7px",
                            border: `1px solid ${tmrColorPalette.grey[100]}`,
                        }}
                    >
                        <ChevronRight
                            sx={{
                                width: "24px",
                                height: "24px",
                                color: tmrColorPalette.grey[900],
                            }}
                        />
                    </IconButton>
                </Box>
            )}

            <Box
                id={"watchMore"}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: isMobile ? "40px" : "60px",
                }}
            >
                <Button
                    id={"watchMore"}
                    onClick={() => {
                        window
                            ?.open(
                                "https://docs.channel.io/0614/ko/categories/d246812e-%EB%8F%84%EC%9E%85-%EC%82%AC%EB%A1%80",
                            )
                            ?.focus();
                    }}
                    sx={{
                        display: "inline-flex",
                        padding: "15px 20px",
                        borderRadius: "50px",
                        textAlign: "center",
                        // lineHeight: "150%",
                        // fontWeight: "500",
                        // fontSize: "18px",
                        height: "53px",
                        backgroundColor: tmrColorPalette.grey[900],
                        ":hover": {
                            color: tmrColorPalette.background[100],
                            backgroundColor: tmrColorPalette.grey[900],
                        },
                    }}
                >
                    <Typography
                        id={"watchMore"}
                        variant="f18M"
                        sx={{
                            lineHeight: 1.3,
                            color: tmrColorPalette.background[100],
                        }}
                    >
                        도입사례 더보기
                    </Typography>
                </Button>
            </Box>
        </LandingLayout>
    );
};

export default Section2_1;
