import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const ArrayCircleLeft = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
            >
                <circle
                    cx="20"
                    cy="20"
                    r="19"
                    transform="rotate(-180 20 20)"
                    fill="white"
                    stroke="#1F2462"
                    strokeWidth="2"
                />
                <path
                    d="M28 21C28.5523 21 29 20.5523 29 20C29 19.4477 28.5523 19 28 19L28 21ZM11.2929 19.2929C10.9024 19.6834 10.9024 20.3166 11.2929 20.7071L17.6569 27.0711C18.0474 27.4616 18.6805 27.4616 19.0711 27.0711C19.4616 26.6805 19.4616 26.0474 19.0711 25.6569L13.4142 20L19.0711 14.3431C19.4616 13.9526 19.4616 13.3195 19.0711 12.9289C18.6805 12.5384 18.0474 12.5384 17.6569 12.9289L11.2929 19.2929ZM28 19L12 19L12 21L28 21L28 19Z"
                    fill="#131651"
                />
            </svg>
        </SvgIcon>
    );
};

export default ArrayCircleLeft;
