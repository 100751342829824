import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const XDownloadIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7941 7C16.8987 7 17.7941 7.89543 17.7941 9V21.2304L21.4878 17.5368C22.2688 16.7557 23.5351 16.7557 24.3162 17.5368C25.0972 18.3178 25.0972 19.5841 24.3162 20.3652L17.2083 27.473C16.4273 28.2541 15.161 28.2541 14.3799 27.473L7.27206 20.3652C6.49102 19.5841 6.49102 18.3178 7.27206 17.5368C8.05311 16.7557 9.31944 16.7557 10.1005 17.5368L13.7941 21.2304V9C13.7941 7.89543 14.6896 7 15.7941 7ZM3 24.0588C4.10457 24.0588 5 24.9543 5 26.0588V27.7647C5 28.9919 5.00156 29.7921 5.05146 30.4029C5.09946 30.9904 5.18203 31.2245 5.24684 31.3517C5.46397 31.7778 5.81042 32.1243 6.23655 32.3414C6.36375 32.4062 6.59788 32.4888 7.18535 32.5368C7.79616 32.5867 8.5963 32.5882 9.82353 32.5882H21.7647C22.9919 32.5882 23.7921 32.5867 24.4029 32.5368C24.9904 32.4888 25.2245 32.4062 25.3517 32.3414C25.7778 32.1243 26.1243 31.7778 26.3414 31.3517C26.4062 31.2245 26.4888 30.9904 26.5368 30.4029C26.5867 29.7921 26.5882 28.9919 26.5882 27.7647V26.0588C26.5882 24.9543 27.4837 24.0588 28.5882 24.0588C29.6928 24.0588 30.5882 24.9543 30.5882 26.0588V27.7647V27.8427C30.5883 28.9704 30.5883 29.9354 30.5235 30.7286C30.4553 31.5634 30.3054 32.3826 29.9054 33.1676C29.3048 34.3464 28.3464 35.3048 27.1676 35.9054C26.3826 36.3054 25.5635 36.4553 24.7286 36.5235C23.9354 36.5883 22.9704 36.5883 21.8427 36.5882H21.7647H9.82353H9.74551C8.61785 36.5883 7.65287 36.5883 6.85962 36.5235C6.02479 36.4553 5.20566 36.3054 4.42059 35.9054C3.24181 35.3048 2.28343 34.3464 1.68282 33.1676C1.2828 32.3826 1.13296 31.5634 1.06475 30.7286C0.999938 29.9354 0.999967 28.9704 1 27.8428L1 27.7647V26.0588C1 24.9543 1.89543 24.0588 3 24.0588Z"
                    fill="black"
                />
                <path
                    d="M29 3L37.3333 11.3333M37.3333 3L29 11.3333"
                    stroke="black"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
};

export default XDownloadIcon;
